import React from 'react';

function AboutAside({ }) {
  return (
    <aside
      className="about__aside"
      style={{ position: 'sticky', marginTop: '60px' }}
    >
      <h2 className="show-aside-title">Upcoming Shows:</h2>
      <ol className="event-listings artist-best-bet-box ">

      </ol>
      <h2 className="show-aside-title">Past Shows:</h2>
      <ol className="event-listings artist-best-bet-box ">
        <li title="Thursday 22 September 2022" className="event-listing ">
          <a
            href="https://www.songkick.com/concerts/40369776-aoife-scott-at-glad-cafe"
            rel="noreferrer nofollow"
            target="blank"
          >
            <time dateTime="2022-09-22T19:00:00+0100"></time>

            <div className="date-box">
              <h4 className="month">Sep</h4>
              <h3 className="date">22</h3>
            </div>

            <div className="event-details concert">
              <strong className="primary-detail">Glasgow, UK</strong>
              <p className="secondary-detail">Glad Café</p>
            </div>
          </a>
        </li>
        <li title="Thursday 01 September 2022" className="event-listing ">
          <a
            href="https://www.songkick.com/concerts/40577240-nicolette-macleod-at-alchemy-experiment"
            rel="noreferrer nofollow"
            target="blank"
          >
            <time dateTime="2022-09-01T18:00:00+0100"></time>

            <div className="date-box">
              <h4 className="month">Sep</h4>
              <h3 className="date">1</h3>
            </div>

            <div className="event-details concert">
              <strong className="primary-detail">Glasgow, UK</strong>
              <p className="secondary-detail">The Alchemy Experiment</p>
            </div>
          </a>
        </li>
        <li
          title="Saturday 20 August 2022 – Saturday 20 August 2022"
          className="event-listing "
        >
          <a
            target="blank"
            rel="noreferrer nofollow"
            href="https://www.songkick.com/festivals/3462230-all-kinds-of-folk/id/40514480-all-kinds-of-folk-2022"
          >
            <time dateTime="2022-08-20"></time>

            <div className="date-box">
              <h4 className="month">Aug</h4>
              <h3 className="date">20</h3>
            </div>

            <div className="event-details festival">
              <strong className="primary-detail">Amberley, UK</strong>
              <p className="secondary-detail">All Kinds of Folk</p>
            </div>
          </a>
        </li>
        <li title="Monday 06 June 2022" className="event-listing ">
          <a
            href="https://www.songkick.com/concerts/40405492-nicolette-macleod-at-isle-of-colonsay-village-hall"
            rel="nofollow"
          >
            <time dateTime="2022-06-06T19:30:00+0100"></time>

            <div className="date-box">
              <h4 className="month">Jun</h4>
              <h3 className="date">6</h3>
            </div>

            <div className="event-details concert">
              <strong className="primary-detail">Oban, UK</strong>
              <p className="secondary-detail">
                Isle of Colonsay - Village Hall
              </p>
            </div>
          </a>
        </li>
        <li title="Saturday 26 March 2022" className="event-listing ">
          <a
            rel="noreferrer nofollow"
            target="blank"
            href="https://www.songkick.com/concerts/40245380-nicolette-macleod-at-cairndow-village-hall"
          >
            <time dateTime="2022-03-26T19:00:00+0000"></time>

            <div className="date-box">
              <h4 className="month">Mar</h4>
              <h3 className="date">26</h3>
            </div>

            <div className="event-details concert">
              <strong className="primary-detail">Inveraray, UK</strong>
              <p className="secondary-detail">cairndow village hall</p>
            </div>
          </a>
        </li>
        <li title="Friday 18 March 2022" className="event-listing ">
          <a
            target="blank"
            rel="noreferrer nofollow"
            href="https://www.songkick.com/concerts/40245371-nicolette-macleod-at-rockfield-centre-community-hut"
          >
            <time dateTime="2022-03-18T19:00:00+0000"></time>

            <div className="date-box">
              <h4 className="month">Mar</h4>
              <h3 className="date">18</h3>
            </div>

            <div className="event-details concert">
              <strong className="primary-detail">Oban, UK</strong>
              <p className="secondary-detail">
                The Rockfield Centre Community Hut
              </p>
            </div>
          </a>
        </li>
        <li title="Saturday 12 March 2022" className="event-listing ">
          <a
            rel="noreferrer nofollow"
            target="blank"
            href="https://www.songkick.com/concerts/40245367-nicolette-macleod-at-locus-centre"
          >
            <time dateTime="2022-03-12T19:00:00+0000"></time>

            <div className="date-box">
              <h4 className="month">Mar</h4>
              <h3 className="date">12</h3>
            </div>

            <div className="event-details concert">
              <strong className="primary-detail">Aberfeldy, UK</strong>
              <p className="secondary-detail">The Locus Centre</p>
            </div>
          </a>
        </li>

        <li title="Saturday 26 February 2022" className="event-listing ">
          <a
            target="blank"
            rel="noreferrer nofollow"
            href="https://www.ticketsource.co.uk/whats-on/ardfern/craignish-village-hall/the-sea-and-me-nicolette-macleod/2022-02-26/19:00/t-dygevj"
          >
            <time dateTime="2022-02-26T19:00:00+0000"></time>

            <div className="date-box">
              <h4 className="month">Feb</h4>
              <h3 className="date">26</h3>
            </div>

            <div className="event-details concert">
              <strong className="primary-detail">Oban, UK</strong>
              <p className="secondary-detail">
                Craignish Village Hall, Ardfern
              </p>
            </div>
          </a>
        </li>
      </ol>
    </aside>
  );
}

export default AboutAside;
